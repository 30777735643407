import { useState, useEffect } from "react";
import filterIcon from "../images/filterIcon.png";
import arrowIcon from "../images/arrowIcon.png";
import classes from "./SuperAdmin.module.css";
import ParticipantItem from "../components/ParticipantItem";
import { useLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import SuperAdminGroupDetailsItem from "../components/SuperAdminGroupDetailsItem";

const SuperAdmin = () => {
  const loaderData = useLoaderData();
  const [groups, setGroups] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [visibleGroups, setVisibleGroups] = useState(5);
  const [visibleParticipants, setVisibleParticipants] = useState(5);
  const [groupSearchTerm, setGroupSearchTerm] = useState('');
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState('all');

  // Calculate date 3 days ago for filtering
  const threeDaysAgo = new Date();
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

  useEffect(() => {
    if (loaderData) {
      const { groups, users } = loaderData;

      groups
        .then(({ response }) => {
          const groupsData = response.data.msg;
          setGroups(groupsData);
          setFilteredGroups(groupsData);
        })
        .catch(({ error }) => {
          toast.error(error?.response?.data?.msg || "Failed to fetch groups");
        });

      users
        .then(({ response }) => {
          const usersData = response.data.msg;
          setParticipants(usersData);
          setFilteredParticipants(usersData);
        })
        .catch(({ error }) => {
          toast.error(error?.response?.data?.msg || "Failed to fetch users");
        });
    }
  }, [loaderData]);

  // Helper function to check if a request is pending
  const isPendingRequest = (req) => {
    return req.status?.id === 1 || req.status === 1 || req.status?.label === "Pending";
  };

  // Filter functions
  const filterRedGroups = (groups) => {
    return groups.filter(group => {
      const lastActivity = new Date(group.updatedAt);
      const requestsCount = group.requests ? group.requests.filter(req => isPendingRequest(req)).length : 0;
      const participantsCount = group.participants || 0;
      
      return lastActivity < threeDaysAgo && 
             requestsCount > participantsCount && 
             group.isVisible === true;
    });
  };

  const filterOrangeGroups = (groups) => {
    return groups.filter(group => {
      const lastActivity = new Date(group.updatedAt);
      const requestsCount = group.requests ? group.requests.filter(req => isPendingRequest(req)).length : 0;
      const participantsCount = group.participants || 0;
      
      return lastActivity < threeDaysAgo && 
             requestsCount > participantsCount && 
             group.isVisible === false;
    });
  };

  const filterGreenGroups = (groups) => {
    return groups.filter(group => {
      const requestsCount = group.requests ? group.requests.filter(req => isPendingRequest(req)).length : 0;
      return group.isVisible === true && requestsCount >= 5;
    });
  };

  // Handle filter changes
  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    
    if (filter === 'all') {
      setFilteredGroups(groups);
    } else if (filter === 'red') {
      setFilteredGroups(filterRedGroups(groups));
    } else if (filter === 'orange') {
      setFilteredGroups(filterOrangeGroups(groups));
    } else if (filter === 'green') {
      setFilteredGroups(filterGreenGroups(groups));
    }
  };

  // Handle group search
  useEffect(() => {
    let results = groups;
    
    // Apply active filter
    if (activeFilter === 'red') {
      results = filterRedGroups(results);
    } else if (activeFilter === 'orange') {
      results = filterOrangeGroups(results);
    } else if (activeFilter === 'green') {
      results = filterGreenGroups(results);
    }
    
    // Apply search term
    if (groupSearchTerm) {
      results = results.filter(group => {
        const groupName = `${group.speciality.label} ${group.type.label}`.toLowerCase();
        return groupName.includes(groupSearchTerm.toLowerCase());
      });
    }
    
    setFilteredGroups(results);
  }, [groupSearchTerm, groups, activeFilter]);

  // Handle user search
  useEffect(() => {
    if (userSearchTerm) {
      const results = participants.filter(user => {
        return user.name.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
               user.email.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
               (user.university?.label || '').toLowerCase().includes(userSearchTerm.toLowerCase());
      });
      setFilteredParticipants(results);
    } else {
      setFilteredParticipants(participants);
    }
  }, [userSearchTerm, participants]);

  // Load more handlers
  const loadMoreGroups = () => {
    setVisibleGroups(prev => prev + 5);
  };

  const loadMoreParticipants = () => {
    setVisibleParticipants(prev => prev + 5);
  };

  // Get filtered and limited items
  const getVisibleGroups = () => {
    return filteredGroups.slice(0, visibleGroups);
  };

  const getVisibleParticipants = () => {
    return filteredParticipants.slice(0, visibleParticipants);
  };

  return (
    <section className={classes.adminPage}>
      <div className={`container ${classes.groupsContainer}`}>
        <div className="row g-sm-4 g-3">
          <div className="col-auto d-flex align-items-center">
            <h1 className={classes.pageSubTitle}>Groups</h1>
          </div>
          <div className="col">
            <input
              type="text"
              className={classes.searchInputBox}
              placeholder="Search Groups"
              value={groupSearchTerm}
              onChange={(e) => setGroupSearchTerm(e.target.value)}
            />
          </div>
          <div className="col-auto d-sm-flex d-none align-items-center">
            <div className={classes.filterContainer}>
              <img 
                className={classes.filterIcon} 
                src={filterIcon} 
                alt="Filter" 
                onClick={() => setActiveFilter(activeFilter === 'all' ? 'red' : activeFilter === 'red' ? 'orange' : activeFilter === 'orange' ? 'green' : 'all')}
              />
              <div className={classes.filterDropdown}>
                <div 
                  className={`${classes.filterOption} ${activeFilter === 'all' ? classes.active : ''}`}
                  onClick={() => handleFilterChange('all')}
                >
                  All Groups
                </div>
                <div 
                  className={`${classes.filterOption} ${classes.redFilter} ${activeFilter === 'red' ? classes.active : ''}`}
                  onClick={() => handleFilterChange('red')}
                >
                  Red Filter ({'>'}3 days, requests {'>'}  participants, visible)
                </div>
                <div 
                  className={`${classes.filterOption} ${classes.orangeFilter} ${activeFilter === 'orange' ? classes.active : ''}`}
                  onClick={() => handleFilterChange('orange')}
                >
                  Orange Filter ({'>'}3 days, requests {'>'}  participants, not visible)
                </div>
                <div 
                  className={`${classes.filterOption} ${classes.greenFilter} ${activeFilter === 'green' ? classes.active : ''}`}
                  onClick={() => handleFilterChange('green')}
                >
                  Green Filter (visible groups with ≥ 5 pending requests)
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.groupListContainer}>
          {getVisibleGroups().map((group, key) => {
            // Determine if this group matches the filter criteria
            const lastActivity = new Date(group.updatedAt);
            const requestsCount = group.requests ? group.requests.filter(req => isPendingRequest(req)).length : 0;
            const participantsCount = group.participants || 0;
            
            const isRedFilter = lastActivity < threeDaysAgo && 
                               requestsCount > participantsCount && 
                               group.isVisible === true;
                               
            const isOrangeFilter = lastActivity < threeDaysAgo && 
                                  requestsCount > participantsCount && 
                                  group.isVisible === false;
                                  
            const isGreenFilter = group.isVisible === true && requestsCount >= 5;
            
            const filterClass = isRedFilter 
              ? classes.redFilterItem 
              : isOrangeFilter 
                ? classes.orangeFilterItem 
                : isGreenFilter
                  ? classes.greenFilterItem
                  : '';
                
            return (
              <div className={`col-12 ${filterClass}`} key={key}>
                <SuperAdminGroupDetailsItem
                  id={group.id}
                  name={`${group.title} - ${group.speciality.label} ${group.type.label}`}
                  participantsCount={group.participants}
                  category={group.speciality.label}
                  subCategory={group.type.label}
                />
              </div>
            );
          })}
        </div>
        
        {filteredGroups.length > visibleGroups && (
          <div className={classes.loadMoreContainer}>
            <button className={classes.loadMoreButton} onClick={loadMoreGroups}>
              <span>Load More</span>
              <img src={arrowIcon} alt="Load more" className={classes.loadMoreIcon} />
            </button>
          </div>
        )}
      </div>
      
      <div className={`container ${classes.groupsContainer}`}>
        <div className="row g-sm-4 g-3">
          <div className="col-auto d-flex align-items-center">
            <h1 className={classes.pageSubTitle}>Users</h1>
          </div>
          <div className="col">
            <input
              type="text"
              className={classes.searchInputBox}
              placeholder="Search Users"
              value={userSearchTerm}
              onChange={(e) => setUserSearchTerm(e.target.value)}
            />
          </div>
          <div className="col-auto d-sm-flex d-none align-items-center">
            <img className={classes.filterIcon} src={filterIcon} alt="Filter" />
          </div>
        </div>
        <div className={classes.groupListContainer}>
          {getVisibleParticipants().map((participant, key) => (
            <ParticipantItem
              key={key}
              id={participant.id}
              name={participant.name}
              year={participant.year.label}
              university={participant.university.label}
              interests={participant.intreset}
              email={participant.email}
              phone={participant.phone}
              // here "isOwner" refers to the owner of the group. Not the owner of the participantItem
              isOwner={false}
              isAccessedByAdmin={true}
            />
          ))}
        </div>
        
        {filteredParticipants.length > visibleParticipants && (
          <div className={classes.loadMoreContainer}>
            <button className={classes.loadMoreButton} onClick={loadMoreParticipants}>
              <span>Load More</span>
              <img src={arrowIcon} alt="Load more" className={classes.loadMoreIcon} />
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default SuperAdmin;
