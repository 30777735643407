import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "./SignupModal.module.css";
import { authContext, loginContext } from "../App";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PrimaryActionButton from "./PrimaryActionButton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SignupModalStepper from "./SignupModalStepper";
import {
  universities,
  years,
  genders,
  specialties as allInterests,
  majors,
} from "../data/constants";
import FormikSelectInput from "./FormikSelectInput";
import FormikInputField from "./FormikInputField";
import useHttp from "../custom-hooks/useHttp";
import { BeatLoader } from "react-spinners";
import FormikAutoCompleteSelectInput from "./FormikAutoCompleteSelectInput";
import SearchAndSelectInterestsField from "./SearchAndSelectInterestsField";

export default function SignupModal(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [interests, setInterests] = useState([]);
  const [interestsError, setInterestsError] = useState(false);
  const [interestsErrorMessage, setInterestsErrorMessage] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const navigate = useNavigate();
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();
  const { setShowLoginModal } = useContext(loginContext);

  // initial values passed to the form
  const initialValues = {
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    ConfirmPassword: "",
    gender: "",
    contact_number: "",
    university: "",
    major: "",
    year: "",
    experience: "",
    isSocial: false,
  };

  const onSubmit = (values) => {
    if (interests.length === 0) {
      setInterestsError(true);
      setInterestsErrorMessage("This field is required");
      return;
    }
    if (parseInt(interests.length) > 3) {
      setInterestsError(true);
      setInterestsErrorMessage("You can select only 3 interests at most");
      return;
    }

    const formValues = {
      name: `${values.firstname} ${values.lastname}`,
      gender: parseInt(values.gender),
      email: values.email,
      phone: values.contact_number,
      password: values.password,
      passwordConfirmation: values.ConfirmPassword,
      university: parseInt(values.university),
      major: parseInt(values.major),
      year: parseInt(values.year),
      intreset: interests.map((interest) => interest.value),
      experience: JSON.parse(values.experience),
      isSocial: JSON.parse(values.isSocial),
      telegram: "",
    };

    sendRequestAndTrackProgress("post", "/signup", formValues);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //form validation rules
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^[a-zA-Z0-9._%+-]+@(gmail|yahoo|hotmail|outlook|icloud)\.(com|net|org|edu)$/,
        "Please use a valid email from common providers (gmail, yahoo, hotmail, outlook, icloud)"
      )
      .required("This field is required!"),
    password: Yup.string().required("This field is required!").min(8),
    ConfirmPassword: Yup.string()
      .required("This field is required!")
      .oneOf([Yup.ref("password"), null], "Passwords do not match"),
    firstname: Yup.string().required("This field is required!").min(3),
    lastname: Yup.string().required("This field is required!").min(3),
    gender: Yup.string().required("This field is required"),
    contact_number: Yup.string()
      .matches(
        /^\+(966|965)\d{8,9}$/,
        "Phone number must start with +966 or +965 followed by 8 or 9 digits"
      )
      .required("This field is required"),
    university: Yup.string().required("This field is required"),
    major: Yup.string().required("This field is required"),
    year: Yup.string().required("This field is required"),
    experience: Yup.string().required("This field is required"),
    isSocial: Yup.string().required("This field is required"),
  });

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        props.onHide();
        toast.success("Signup successful. Please login to continue.");
      } else {
        toast.error(actionData.error?.response?.data?.msg);
      }
    }
  }, [actionData]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={classes.customModalBody}>
        <div className={classes.modalCloseButtonContainer}>
          <span
            className={`material-symbols-outlined ${classes.modalCloseButton}`}
            onClick={() => props.onHide()}
          >
            close
          </span>
        </div>
        <div className={classes.modalBodyContainer}>
          <h3 className={classes.title}>Sign up</h3>
          <SignupModalStepper activeStep={activeStep} />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form>
                  <div
                    className={`${classes.firstStep} ${
                      activeStep === 0 ? "d-block" : "d-none"
                    }`}
                  >
                    <div className={classes.formRow}>
                      <FormikInputField
                        label="Email"
                        inputType="email"
                        fieldName="email"
                      />
                    </div>
                    <div className={classes.formRow}>
                      <FormikInputField
                        label="First Name"
                        inputType="text"
                        fieldName="firstname"
                      />
                    </div>
                    <div className={classes.formRow}>
                      <FormikInputField
                        label="Last Name"
                        inputType="text"
                        fieldName="lastname"
                      />
                    </div>
                    <div className={classes.formRow}>
                      <FormikInputField
                        label="Password"
                        inputType="password"
                        fieldName="password"
                      />
                    </div>
                    <div className={classes.formRow}>
                      <FormikInputField
                        label="Confirm Password"
                        inputType="password"
                        fieldName="ConfirmPassword"
                      />
                    </div>
                    <PrimaryActionButton
                      onClick={() => {
                        formik.setTouched({
                          firstname: true,
                          lastname: true,
                          email: true,
                          password: true,
                          ConfirmPassword: true,
                        });

                        // Check if the specific fields are valid
                        const fieldsToValidate = [
                          "firstname",
                          "lastname",
                          "email",
                          "password",
                          "ConfirmPassword",
                        ];
                        const isValid = fieldsToValidate.every(
                          (field) => !formik.errors[field]
                        );

                        // If all specified fields are valid, call handleNext
                        if (isValid) {
                          handleNext();
                        }
                      }}
                      buttonText={"Next"}
                      type="button"
                      className={`${classes.submitBtn} ${classes.firstStepSubmitBtn}`}
                    />
                  </div>
                  <div
                    className={`${classes.secondStep} ${
                      activeStep === 1 ? "d-block" : "d-none"
                    }`}
                  >
                    <div className={classes.formRow}>
                      <FormikSelectInput
                        options={genders}
                        defaultValue="Select Gender"
                        name="gender"
                        label="Gender"
                        required={true}
                      />
                    </div>
                    <div className={classes.formRow}>
                      <FormikInputField
                        label="Contact Number"
                        inputType="text"
                        fieldName="contact_number"
                        required={true}
                      />
                    </div>
                    <div className={classes.formRow}>
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikAutoCompleteSelectInput
                          options={universities.sort((a, b) =>
                            a.key.localeCompare(b.key)
                          )}
                          name="university"
                          label="University"
                          setFieldValue={formik.setFieldValue}
                          required={true}
                        />
                      </div>
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikAutoCompleteSelectInput
                          options={majors.sort((a, b) =>
                            a.key.localeCompare(b.key)
                          )}
                          name="major"
                          label="Major"
                          setFieldValue={formik.setFieldValue}
                          required={true}
                        />
                      </div>
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikSelectInput
                          options={years}
                          defaultValue="Select Year"
                          name="year"
                          label="Year"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className={classes.formRow}>
                      <SearchAndSelectInterestsField
                        allInterests={allInterests.sort((a, b) =>
                          a.key.localeCompare(b.key)
                        )}
                        required={true}
                        interestsError={interestsError}
                        setInterestsError={setInterestsError}
                        interestsErrorMessage={interestsErrorMessage}
                        setInterestsErrorMessage={setInterestsErrorMessage}
                        setInterests={setInterests}
                      />
                    </div>
                    <div className={classes.formRow}>
                      <div className={`col-sm col-12 ${classes.inputSpacer}`}>
                        <FormikSelectInput
                          options={[
                            { value: true, key: "Yes" },
                            { value: false, key: "No" },
                          ]}
                          defaultValue="Select Value"
                          name="experience"
                          label="Have you ever published a paper?"
                          required={true}
                        />
                      </div>
                      <div
                        className={`d-none col-sm col-12 ${classes.inputSpacer}`}
                      >
                        <FormikSelectInput
                          options={[
                            { value: true, key: "Yes" },
                            { value: false, key: "No" },
                          ]}
                          defaultValue="Select Value"
                          name="isSocial"
                          label="Is Social"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className={classes.agreeTermsAndconditionsContainer}>
                      <div>
                        <input
                          type="checkbox"
                          className={classes.termsAndConditionsCheckbox}
                          checked={agreeTerms}
                          onChange={(e) => setAgreeTerms(e.target.checked)}
                        />
                      </div>
                      <p className={classes.termsAndConditionsText}>
                        I agree to the website's{" "}
                        <span className={classes.termsAndConditionsLink}>
                          terms & conditions
                        </span>
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <PrimaryActionButton
                          onClick={handleBack}
                          buttonText={"Back"}
                          type="button"
                          className={`${classes.submitBtn}`}
                        />
                      </div>
                      <div className="col-6">
                        <PrimaryActionButton
                          disabled={agreeTerms ? false : true}
                          buttonText={
                            isLoading ? (
                              <BeatLoader color="white" loading size={10} />
                            ) : (
                              <p style={{ margin: "0" }}>Submit</p>
                            )
                          }
                          type="submit"
                          className={classes.submitBtn}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <p className={classes.loginText}>
            Already have an account?{" "}
            <span
              className={classes.loginButton}
              onClick={() => {
                props.onHide();
                setShowLoginModal(true);
              }}
            >
              log in
            </span>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
