import { useState, useEffect } from "react";
import classes from "./CommunityPage.module.css";
import filterIcon from "../images/filterIcon.png";
import SingleCategoryItem from "../components/SingleCategoryItem";
import GroupCarousel from "../components/GroupCarousel";
import WelcomeMessage from "../components/WelcomeMessage";
import CreateGroupModal from "../components/CreateGroupModal";
import { useLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import HomePageMyGroupsSection from "../components/HomePageMyGroupsSection";
import ResendVerificationLink from "../components/ResendVerificationLink";

const CommunityPage = () => {
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [pageData, setPageData] = useState({
    groups: [],
    threeGroups: [],
    isEmailVerified: true,
    categories: []
  });
  const [filterGroups, setFilterGroups] = useState(null);
  const loaderData = useLoaderData();

  useEffect(() => {
    if (loaderData) {
      const { myGroups, threeGroups, isEmailVerified } = loaderData;
      
      Promise.all([
        isEmailVerified,
        myGroups,
        threeGroups
      ]).then(([emailVerified, groups, threeGroupsData]) => {
        setPageData(prev => ({
          ...prev,
          isEmailVerified: emailVerified.response?.data?.isVerified,
          groups: groups.response?.data?.msg || [],
          threeGroups: threeGroupsData.response?.data?.msg || [],
          categories: [...new Set(threeGroupsData.response?.data?.msg?.map(group => group.type.label) || [])]
        }));
      }).catch((error) => {
        toast.error(error?.response?.data?.msg || "Try to refresh the page");
      });
    }
  }, [loaderData]);

  return (
    <section className={classes.communityPage}>
      <div className={`container ${classes.welcomeMessageContainer}`}>
        <div className={classes.communityHeader}>
          <h1 className={classes.communityTitle}>RH Community</h1>
          <p className={classes.communitySubtitle}>
            Connect with researchers worldwide and join our thriving academic community
          </p>
        </div>
        {!pageData.isEmailVerified && (
          <div className={classes.verificationMessage}>
            <div className={classes.verificationInner}>
              <p>Please verify your email - <ResendVerificationLink /></p>
            </div>
          </div>
        )}
      </div>
      <HomePageMyGroupsSection
        groups={pageData.groups}
        setShowCreateGroupModal={setShowCreateGroupModal}
      />
      {pageData.threeGroups.length > 0 && (
        <div className={`container ${classes.joinGroup}`}>
          <div className={classes.joinGroupCard}>
            <div className={`d-flex ${classes.joinGroupTitleContianer}`}>
              <div className="d-flex align-items-center">
                <h1 className={`${classes.pageSubTitle}`}>Join a Group</h1>
              </div>
              <div
                className={`col-auto d-flex align-items-center ${classes.myGroupsButton}`}
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div className={`d-flex align-items-center ${classes.filterIconWrapper}`}>
                  <img
                    className={classes.filterIcon}
                    src={filterIcon}
                    alt="Filter icon"
                  />
                </div>
              </div>
            </div>
            <div
              id="collapseTwo"
              className={`accordion-collapse collapse show ${classes.accordionBody}`}
              data-bs-parent="#accordionExample"
            >
              <div className={classes.categoriesWrapper}>
                <div className={`row g-3 ${classes.categoriesContainer}`}>
                  {pageData.categories.map((category, key) => (
                    <div className="col-auto" key={key}>
                      <SingleCategoryItem
                        category={category}
                        filterGroup={filterGroups}
                        setFilterGroups={setFilterGroups}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.groupCarouselContainer}>
            <GroupCarousel
              groups={
                filterGroups
                  ? pageData.threeGroups.filter(
                      (group) =>
                        group.type.label.toLowerCase() ===
                        filterGroups.toLowerCase()
                    )
                  : pageData.threeGroups
              }
            />
          </div>
        </div>
      )}
      <CreateGroupModal
        show={showCreateGroupModal}
        onHide={() => setShowCreateGroupModal(false)}
      />
    </section>
  );
};

export default CommunityPage; 