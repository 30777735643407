import { defer } from "react-router-dom";

const findColleagueDataLoader = (sendRequest) => (args) => {
  const searchParams = new URLSearchParams(args.request.url.split('?')[1]);
  const university = searchParams.get('university');
  const interests = searchParams.getAll('interest');

  let url = '/users';
  const params = new URLSearchParams();
  
  if (university && !isNaN(university)) {
    params.append('university', university);
  }
  
  // Filter out non-numeric values and send interests as a single array
  if (interests && interests.length > 0) {
    const validInterests = interests.filter(interest => !isNaN(interest));
    if (validInterests.length > 0) {
      params.append('interest', JSON.stringify(validInterests));
    }
  }

  const finalUrl = `${url}?${params.toString()}`;
  return sendRequest("get", finalUrl).then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

const getSuggestedColleagues = (sendRequest) => {
  return sendRequest("get", "/suggested-colleagues").then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

export const findColleaguePageLoader = (sendRequest) => async (args) => {
  const users = findColleagueDataLoader(sendRequest)(args);
  const suggestedColleagues = getSuggestedColleagues(sendRequest);

  return defer({
    users,
    suggestedColleagues
  });
};