import { useState } from "react";
import Newsletter from "../components/Newsletter";
import Bundle from "../components/Bundle";
import ResearchServiceBundle from "../components/ResearchServiceBundle";
import classes from "./SchoolsPage.module.css";
import axiomImage from "../../src/images/schools/axiom.png";
import learnologyImage from "../../src/images/schools/learnology.png";
const SchoolsPage = () => {
  const [activeTab, setActiveTab] = useState("all");

  // Helper function to convert study types string into an array of tags
  const convertStudyTypesToTags = (studyTypesString) => {
    if (!studyTypesString) return [];
    return studyTypesString.split(', ').map(type => type.trim());
  };

  // Define schools with updated categories
  // isHidden property: when set to true, the bundle will not be displayed in any filter view
  const schools = [
    {
      id: 1,
      name: "Axiom",
      price: "350 SR",
      category: "schools", 
      image: axiomImage,
      lectureCount: "17",
      hasMentorship: true,
      hasPublishedPapers: false,
      editionCount: "1",
      studentCount: "100+",
      studiesType: "Systemic Review & Meta-Analysis",
      isHidden: false,
      url: "https://ishortn.ink/yWEkyfWUY",
    },
    {
      id: 2,
      name: "Edrak",
      price: "199 SR",
      category: "schools",
      image: "https://pbs.twimg.com/profile_images/1818952399697907712/oM-NnYj__400x400.jpg", 
      lectureCount: "10",
      hasMentorship: true,
      hasPublishedPapers: true,
      editionCount: "4",
      studentCount: "500+",
      studiesType: "Systemic Review & Meta-Analysis",
      isHidden: false,
      url: "https://ishortn.ink/8DB1waw18",
    },
    {
      id: 3,
      name: "ScriptMark",
      price: "340 SR",
      category: "schools",
      image: "https://pbs.twimg.com/profile_images/1681648001188601862/aYbJe_N5_400x400.jpg",
      lectureCount: "N/A",
      hasMentorship: true,
      hasPublishedPapers: true,
      editionCount: "2", 
      studentCount: "1500+",
      studiesType: "Systemic Review & Meta-Analysis, Narrative Review, Bibliometric Analysis",
      isHidden: true,
      url: "https://myscriptmark.com/",
    },
    {
      id: 4,
      name: "ResearchMate",
      price: "599 SR",
      category: "schools",
      image: "https://pbs.twimg.com/profile_images/1780702470039318528/3xxZ3iGx_400x400.jpg",
      lectureCount: "7-15",
      hasMentorship: true,
      hasPublishedPapers: true,
      editionCount: "5",
      studentCount: "N/A",
      studiesType: "Systemic Review & Meta-Analysis, Narrative Review",
      isHidden: false,
      url: "https://ishortn.ink/QyMqHNW6U",
    },
    {
      id: 5,
      name: "Researchable",
      price: "550 SR",
      category: "schools",
      image: "https://pbs.twimg.com/profile_images/1656124890435469312/4SJFP6FE_400x400.png",
      lectureCount: "14",
      hasMentorship: true,
      hasPublishedPapers: true,
      editionCount: "10",
      studentCount: "4000+",
      studiesType: "Systemic Review & Meta-Analysis, Narrative Review, Bibliometric Analysis, Cross-sectional",
      isHidden: false,
      url: "https://ishortn.ink/6CgfVrQez",
    },
    {
      id: 6,
      name: "Learnology",
      category: "research",
      image: learnologyImage,
      description: "Learnology's mission is to bridge the gap between high-quality research and students. We believe that every student deserves access to excellent academic support and guidance, helping them achieve their full potential in their academic journey.",
      studiesType: "Research Editing, Essay Publishing, Academic Counselling, Newsletter",
      mainFeatures: [
        "Comprehensive academic support tailored to your needs",

      ],
      isHidden: false,
      url: "https://ishortn.ink/AAmZ0pJlN",
      hasFree: true,
    }
  ]

  // Filter schools based on active tab and isHidden property
  const filteredSchools = activeTab === "all" 
    ? schools.filter(school => !school.isHidden) 
    : schools.filter(school => school.category === activeTab && !school.isHidden);

  return (
    <section className={classes.schoolsPage}>
      {/* Intro Section with gradient title and subtitle */}
      <div className={`container ${classes.welcomeMessageContainer}`}>
        <div className={classes.communityHeader}>
          <h1 className={classes.communityTitle}>Research in Saudi Arabia</h1>
          <p className={classes.communitySubtitle}>
            Explore top research institutions and academic programs across Saudi Arabia to find the perfect fit for your educational journey
          </p>
        </div>
      </div>

      <div className={`container ${classes.schoolsContainer}`}>
        {/* Filter tabs - updated to have only 3 tabs */}
        <div className={classes.filterTabs}>
          <button 
            className={`${classes.filterTab} ${activeTab === "all" ? classes.active : ""}`}
            onClick={() => setActiveTab("all")}
          >
            All
          </button>
          <button 
            className={`${classes.filterTab} ${activeTab === "schools" ? classes.active : ""}`}
            onClick={() => setActiveTab("schools")}
          >
            Schools
          </button>
          <button 
            className={`${classes.filterTab} ${activeTab === "research" ? classes.active : ""}`}
            onClick={() => setActiveTab("research")}
          >
            Research Services
          </button>
        </div>

        {/* Schools Grid Container */}
        <div className={classes.schoolsGridContainer}>
          <div className={classes.sectionHeader}>
            <h2 className={classes.sectionTitle}>
              {activeTab === "all" 
                ? "All Research Institutions" 
                : activeTab === "schools" 
                  ? "Educational Institutions" 
                  : "Research Services"}
            </h2>
          </div>

          <div className={classes.schoolsGrid}>
            {filteredSchools.map((item) => (
              <div className={classes.schoolCard} key={item.id}>
                {item.category === "research" ? (
                  <ResearchServiceBundle 
                    name={item.name}
                    icon={item.image}
                    description={item.description}
                    mainFeatures={item.mainFeatures}
                    tags={[
                      ...(item.studiesType ? convertStudyTypesToTags(item.studiesType) : []),
                      ...(item.tags || [])
                    ]}
                    url={item.url}
                    hasFree={item.hasFree}
                    hasDiscount={item.hasDiscount}
                  />
                ) : (
                  <Bundle 
                    name={item.name} 
                    price={item.price} 
                    info={item.info}
                    image={item.image}
                    expandedInfo={item.expandedInfo}
                    lectureCount={item.lectureCount}
                    hasMentorship={item.hasMentorship}
                    hasPublishedPapers={item.hasPublishedPapers}
                    editionCount={item.editionCount}
                    studentCount={item.studentCount}
                    studiesType={item.studiesType}
                    url={item.url}
                    hasFree={item.hasFree}
                    hasDiscount={item.hasDiscount}
                  />
                )}
              </div>
            ))}
          </div>
          <p className={classes.smallNote}>
            Note: Information presented is primarily based on systemic review courses offered by these institutions.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SchoolsPage;
