import { useContext, useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom/dist";
import PrimaryActionButton from "../components/PrimaryActionButton";
import classes from "./NonLoggedInHomePage.module.css";
import coupleImage from "../images/couple.png";
import paperImage from "../images/paper.png";
import Newsletter from "../components/Newsletter";
import PartnersSection from "../components/PartnersSection";
import GroupCarousel from "../components/GroupCarousel";
import { signupContext } from "../App";
import { toast } from "react-toastify";

// Import university logos - these would need to be added to your project
import ksuLogo from "../images/universities/ksu-logo.png";
import kauLogo from "../images/universities/kau-logo.png";
import tuLogo from "../images/universities/tu-logo.png";
import kkuLogo from "../images/universities/kku-logo.png";
import taibahLogo from "../images/universities/taibah-logo.png";

const NonLoggedInHomePage = () => {
  const { setShowSignupModal } = useContext(signupContext);
  const [threeGroups, setThreeGroups] = useState([]);
  const [counters, setCounters] = useState({ users: 0, groups: 0 });
  const loaderData = useLoaderData();

  useEffect(() => {
    if (loaderData) {
      const { threeGroups, counter } = loaderData;

      counter
        .then(({ response }) => {
          setCounters(response.data);
        })
        .catch(({ error }) => {
          toast.error(error?.response?.data?.msg || "Failed to fetch counters");
        });

      threeGroups
        .then(({ response }) => {
          setThreeGroups(response.data.msg);
        })
        .catch(({ error }) => {
          toast.error(error?.response?.data?.msg || "Failed to fetch research groups");
        });
    }
  }, [loaderData]);

  return (
    <div className={classes.homePage}>
      {/* Hero Section */}
      <section className={classes.heroSection}>
        <div className="container">
          <div className={`row ${classes.heroContent}`}>
            <div className="col-lg-8 col-md-10 mx-auto text-center">
              <div className={classes.heroTextContainer}>
                <h1 className={classes.heroTitle}>
                  <span className={classes.highlight}>Researchers Hub</span>
                  <img 
                    src={paperImage} 
                    alt="Paper Icon" 
                    className={classes.heroIcon}
                  />
                </h1>
                <h2 className={classes.heroSubtitle}>
                  Connect, Collaborate, Create Research Groups
                  <img 
                    src={coupleImage} 
                    alt="Collaboration Icon" 
                    className={classes.subtitleIcon}
                  />
                </h2>
                <p className={classes.heroParagraph}>
                  Join our thriving community of academics and researchers to discover collaboration opportunities,
                  connect with peers, and accelerate your research journey.
                </p>
                
                <div className={classes.statsContainer}>
                  <div className={classes.statItem}>
                    <span className={classes.statNumber}>{Math.ceil(counters.users / 10) * 10}+</span>
                    <span className={classes.statLabel}>Authors</span>
                  </div>
                  <div className={classes.statDivider}></div>
                  <div className={classes.statItem}>
                    <span className={classes.statNumber}>{Math.ceil(counters.groups / 10) * 10}+</span>
                    <span className={classes.statLabel}>Research Groups</span>
                  </div>
                </div>
                
                <div className={classes.trustedContainer}>
                  <p className={classes.trustedText}>Trusted by researchers from</p>
                  <div className={classes.universityLogos}>
                    <div className={classes.universityLogo}>
                      <img src={ksuLogo} alt="King Saud University" />
                    </div>
                    <div className={classes.universityLogo}>
                      <img src={kauLogo} alt="King Abdulaziz University" />
                    </div>
                    <div className={classes.universityLogo}>
                      <img src={tuLogo} alt="Taif University" />
                    </div>
                    <div className={classes.universityLogo}>
                      <img src={kkuLogo} alt="King Khalid University" />
                    </div>
                    <div className={classes.universityLogo}>
                      <img src={taibahLogo} alt="Taibah University" />
                    </div>
                  </div>
                </div>
                
                <div className={classes.heroButtonContainer}>
                  <PrimaryActionButton
                    buttonText="Join the Community"
                    className={classes.heroButton}
                    onClick={() => setShowSignupModal(true)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Groups Section */}
      <section className={classes.featuredGroupsSection}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-5">
              <h2 className={classes.sectionTitle}>Featured Research Groups</h2>
              <p className={classes.sectionSubtitle}>
                Discover active research collaborations across various disciplines
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <GroupCarousel groups={threeGroups} />
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className={classes.benefitsSection}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-5">
              <h2 className={classes.sectionTitle}>Why Join Researchers Hub?</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className={classes.benefitCard}>
                <div className={classes.benefitIcon}>🔍</div>
                <h3>Discover Collaborations</h3>
                <p>Find researchers with complementary skills and interests for your next project</p>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className={classes.benefitCard}>
                <div className={classes.benefitIcon}>👥</div>
                <h3>Connect with Colleagues</h3>
                <p>Build a powerful network of academic peers and industry experts in your field</p>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className={classes.benefitCard}>
                <div className={classes.benefitIcon}>🚀</div>
                <h3>Accelerate Research</h3>
                <p>Leverage collective expertise to overcome challenges and advance your work</p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center">
              <PrimaryActionButton
                buttonText="Start Collaborating Today"
                className={classes.secondaryCTA}
                onClick={() => setShowSignupModal(true)}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Partners Section */}
      <PartnersSection />
    </div>
  );
};

export default NonLoggedInHomePage;
