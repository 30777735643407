import { useState } from "react";
import DeleteGroupModal from "./DeleteGroupModal";
import LeaveGroupModal from "./LeaveGroupModal";
import LockInGroupModal from "./LockInGroupModal";
import classes from "./GroupItem.module.css";
import PrimaryActionButton from "./PrimaryActionButton";
import useHttp from "../custom-hooks/useHttp";
import { useEffect } from "react";
import { toast } from "react-toastify";



const GroupDetailsPageGroupItem = ({
  id,
  name,
  title,
  participantsCount,
  category,
  subCategory,
  isOwner,
  isVisible,
  seatsAvailable,
}) => {
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [showLeaveGroupModal, setShowLeaveGroupModal] = useState(false);
  const [showLockInGroupModal, setShowLockInGroupModal] = useState(false);
  const [setVisibleMessage, setSetVisibleMessage] = useState("");
  const [visibleChecked, setVisibleChecked] = useState(isVisible);
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();
  const changeVisiblty = (e) => {
    const data = {
      groupId: id,
      isVisible: e.target.checked,
    }
    sendRequestAndTrackProgress("post", `/visibility/`, data);
    console.log(e.target.checked);
  }

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        toast.success(actionData?.response?.data?.msg);
        setVisibleChecked(actionData?.response?.data?.isVisible);
      } else if (actionData.status === "error") {
        toast.error(  actionData.error?.response?.data?.msg ||  "An error occurred. Please try again later.");
        setVisibleChecked(actionData?.response?.data?.isVisible);
      }
    }
  }, [actionData]);
  return (
    <div className={`${classes.groupContainer}`}>
      <div className="row">
        <div className="col-lg col-12">
          <p>{title}</p>
          <p className={classes.groupName}>{name}</p>
          {!isOwner ? (
            <p className={`${classes.status} ${classes.accepted}`}>accepted</p>
          ) : (
            <p className={`${classes.status}`}></p>
          )}
          <p
            className={classes.metaData}
          >{`${participantsCount} Participants | ${category} | ${subCategory}`}</p>
        </div>
        <div className="col-lg-auto col-12 align-items-center justify-content-end">
          {isOwner ? (
            <div className="d-flex flex-column flex-md-row gap-2">
              <PrimaryActionButton
                className={classes.actionButton}
                buttonText={"delete group ❌"}
                onClick={() => setShowDeleteGroupModal(true)}
              />
              {seatsAvailable === 0 && (
                <PrimaryActionButton
                  className={`${classes.actionButton} ${classes.lockInButton}`}
                  buttonText="LOCK IN 🔒"
                  onClick={() => setShowLockInGroupModal(true)}
                />
              )}
            </div>
          ) : (
            <PrimaryActionButton
              className={classes.actionButton}
              buttonText="leave group 👋"
              onClick={() => setShowLeaveGroupModal(true)}
            />
          )}
          
        </div>
        <div className="row">
        {isOwner && (
            <div className="form-check form-switch mt-4" >
              <div className="d-flex col-lg col-12  m-2">
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={e => changeVisiblty(e)} defaultChecked={visibleChecked}/>
                <p className="px-4">Looking for Researchers</p>
              
                
              </div>
              <p className={`${classes.visibleInfo} flex-grow-1`}>Enabling this switch makes your group visible for others, but it will deactivate if you're inactive for three consecutive days. </p>
          </div>
          )}
        </div>
      </div>
      
      <DeleteGroupModal
        groupId={id}
        show={showDeleteGroupModal}
        onHide={() => setShowDeleteGroupModal(false)}
      ></DeleteGroupModal>
      <LeaveGroupModal
        groupId={id}
        show={showLeaveGroupModal}
        onHide={() => setShowLeaveGroupModal(false)}
      ></LeaveGroupModal>
      <LockInGroupModal
        groupId={id}
        show={showLockInGroupModal}
        onHide={() => setShowLockInGroupModal(false)}
      ></LockInGroupModal>
    </div>
  );
};

export default GroupDetailsPageGroupItem;
