import { useState } from "react";
import classes from "./HomePageMyGroupsSection.module.css";
import PrimaryActionButton from "../components/PrimaryActionButton";
import arrowIcon from "../images/arrowIcon.png";
import HomePageGroupItem from "../components/HomePageGroupItem";

const HomePageMyGroupsSection = ({ groups, setShowCreateGroupModal }) => {
  const [expandGroups, setExpandGroups] = useState(true);

  return (
    <div className={`container ${classes.myGroupsContainer}`}>
      <PrimaryActionButton
        buttonText="create your group"
        className={`${classes.createNewGroupButton} d-md-none d-block`}
        onClick={() => setShowCreateGroupModal(true)}
      />

      <div className="accordion" id="groupsAccordion">
        <div className={classes.myGroupButtonsContainer}>
          <div
            className={classes.myGroupsButton}
            onClick={() => setExpandGroups((prevState) => !prevState)}
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <img
              className={`${expandGroups ? classes.expand : ""} ${classes.expandCollapseIcon}`}
              src={arrowIcon}
              alt="Toggle groups"
            />
            <h1 className={classes.pageSubTitle}>My Groups</h1>
          </div>
          
          <div className="d-md-block d-none">
            <PrimaryActionButton
              buttonText="create your group"
              className={classes.createNewGroupButton}
              onClick={() => setShowCreateGroupModal(true)}
            />
          </div>
        </div>
        
        <div
          id="collapseOne"
          className={`collapse show ${classes.accordionBody}`}
          data-bs-parent="#groupsAccordion"
        >
          {groups.length === 0 ? (
            <div className={classes.emptyGroupsMessage}>
              <p>Currently you do not have any groups to show</p>
            </div>
          ) : (
            <div className="group-items-container">
              {groups.map((group, key) => (
                <div className="mb-3" key={key}>
                  <HomePageGroupItem
                    id={group.id}
                    title={group.title}
                    name={`${group.speciality.label} ${group.type.label}`}
                    requests={group.requests}
                    participantsCount={group.participants}
                    category={group.speciality.label}
                    subCategory={group.type.label}
                    isVisible={group.isVisible}
                    seatsAvailable={group.seatsAvailable}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePageMyGroupsSection;
