import classes from "./ParticipantItem.module.css";
import PrimaryActionButton from "./PrimaryActionButton";
import whatsappIcon from "../images/whatsappLogo.png";
import emailIcon from "../images/emailLogo.png";
import telegramIcon from "../images/telegramLogo.png";
import useHttp from "../custom-hooks/useHttp";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const ParticipantItem = ({
  id,
  groupId,
  groupName,
  name,
  year,
  university,
  major,
  interests,
  email,
  phone,
  telegram,
  // here "isOwner" refers to the owner of the group. Not the owner of the participantItem
  isOwner,
  ownerId,
  isAccessedByAdmin = false,
  className = "",
}) => {
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();
  const navigate = useNavigate();

  const removeParticipant = () => {
    const data = {
      groupId,
      participantId: id,
    };

    sendRequestAndTrackProgress("post", `/remove/`, data);
  };

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        navigate(`/group/${groupId}/${groupName}`);
        toast.success(actionData?.response?.data?.msg);
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
            "An error occurred. Please try again later."
        );
      }
    }
  }, [actionData]);

  // Helper function to format interests nicely
  const formatInterests = () => {
    if (!interests || interests.length === 0) return "No specific interests";
    
    return interests
      .map((interest) => interest.label)
      .join(", ");
  };

  return (
    <div className={`${classes.participantContainer} ${className}`}>
      <div className={classes.participantContent}>
        <div className={classes.participantInfo}>
          <h3 className={classes.userName}>{name}</h3>
          
          <div className={classes.detailsRow}>
            {year && year !== "None" && (
              <div className={classes.detailTag}>
                <span className="material-symbols-outlined">school</span>
                {year}
              </div>
            )}
            
            {university && university !== "None" && (
              <div className={classes.detailTag}>
                <span className="material-symbols-outlined">account_balance</span>
                {university}
              </div>
            )}
            
            {major && major !== "None" && (
              <div className={classes.detailTag}>
                <span className="material-symbols-outlined">psychology</span>
                {major}
              </div>
            )}
          </div>
          
          <div className={classes.interestsSection}>
            <h4 className={classes.interestsTitle}>Research Interests:</h4>
            <p className={classes.interestsText}>{formatInterests()}</p>
          </div>
          
          <div className={classes.contactSection}>
            {telegram && (
              <a 
                href={`https://t.me/${telegram}`} 
                target="_blank" 
                rel="noopener noreferrer"
                className={classes.contactButton}
                aria-label="Contact via Telegram"
              >
                <img className={classes.contactIcon} src={telegramIcon} alt="Telegram" />
                <span>Telegram</span>
              </a>
            )}
            
            {phone && (
              <a 
                href={`https://wa.me/${phone}`} 
                target="_blank" 
                rel="noopener noreferrer"
                className={classes.contactButton}
                aria-label="Contact via WhatsApp"
              >
                <img className={classes.contactIcon} src={whatsappIcon} alt="WhatsApp" />
                <span>WhatsApp</span>
              </a>
            )}
            
            {email && (
              <a 
                href={`mailto:${email}`}
                className={classes.contactButton}
                aria-label="Contact via Email"
              >
                <img className={classes.contactIcon} src={emailIcon} alt="Email" />
                <span>Email</span>
              </a>
            )}
          </div>
        </div>
        
        {isAccessedByAdmin ? (
          <div className={classes.adminActionsContainer}>
            <PrimaryActionButton
              className={`${classes.actionButton} ${classes.removeButton}`}
              buttonText="Remove"
            />
            <PrimaryActionButton
              className={classes.actionButton}
              buttonText="View"
            />
          </div>
        ) : (
          // Only the owner can remove participants
          // The owner can't remove themselves
          isOwner && ownerId !== id && (
            <div className={classes.actionsContainer}>
              <PrimaryActionButton
                className={`${classes.actionButton} ${classes.removeButton}`}
                buttonText={
                  isLoading ? (
                    <BeatLoader color="white" loading size={10} />
                  ) : (
                    "Remove"
                  )
                }
                onClick={removeParticipant}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ParticipantItem;
