import classes from "./Bundle.module.css";
import PrimaryActionButton from "./PrimaryActionButton";
import { useState } from "react";

export default function Bundle(props) {
  // Helper function to convert study types string into an array
  const getStudyTypesTags = () => {
    if (!props.studiesType) return [];
    return props.studiesType.split(', ').map(type => type.trim());
  };

  // Handle button click to open URL in new tab
  const handleButtonClick = () => {
    if (props.url) {
      window.open(props.url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className={`${classes.bundleContainer}`}>
      {/* Header with logo and name */}
      <div className={classes.bundleHeader}>
        <div className={classes.headerLeft}>
          <h2 className={classes.bundleName}>{props.name}</h2>
          <div className={classes.specialTagsContainer}>
            {props.hasFree && (
              <span className={`${classes.specialTag} ${classes.freeTag}`}>
                <span className="material-symbols-outlined">card_giftcard</span>
                <span>Free Resources</span>
              </span>
            )}
            {props.hasDiscount && (
              <span className={`${classes.specialTag} ${classes.discountTag}`}>
                <span className="material-symbols-outlined">local_offer</span>
                <span>Discount Available</span>
              </span>
            )}
          </div>
        </div>
        <div className={classes.headerRight}>
          <img
            className={classes.bundleImage}
            src={props.image}
            alt={props.name}
          />
        </div>
      </div>

      {/* Quick stats section */}
      <div className={classes.statsContainer}>
        <div className={classes.statItem}>
          <div className={classes.statIcon}>
            <span className="material-symbols-outlined">school</span>
          </div>
          <div className={classes.statInfo}>
            <span className={classes.statLabel}>Students</span>
            <span className={classes.statValue}>{props.studentCount || "N/A"}</span>
          </div>
        </div>
        
        <div className={classes.statItem}>
          <div className={classes.statIcon}>
            <span className="material-symbols-outlined">menu_book</span>
          </div>
          <div className={classes.statInfo}>
            <span className={classes.statLabel}>Lectures</span>
            <span className={classes.statValue}>{props.lectureCount || "N/A"}</span>
          </div>
        </div>
        
        <div className={classes.statItem}>
          <div className={classes.statIcon}>
            <span className="material-symbols-outlined">history_edu</span>
          </div>
          <div className={classes.statInfo}>
            <span className={classes.statLabel}>Editions</span>
            <span className={classes.statValue}>{props.editionCount || "N/A"}</span>
          </div>
        </div>
      </div>

      {/* Key information section */}
      <div className={classes.keyInfoSection}>
        <div className={classes.infoRow}>
          <div className={classes.infoLabel}>Starting Price:</div>
          <div className={classes.infoValue}>{props.price}</div>
        </div>
        
        <div className={classes.infoRow}>
          <div className={classes.infoLabel}>Mentorship:</div>
          <div className={classes.infoValue}>{props.hasMentorship ? "Available" : "Not Available"}</div>
        </div>
        
        <div className={classes.infoRow}>
          <div className={classes.infoLabel}>Published Papers:</div>
          <div className={classes.infoValue}>{props.hasPublishedPapers ? "Yes" : "No"}</div>
        </div>
      </div>

      {/* Study types as tags */}
      <div className={classes.tagsContainer}>
        {props.studiesType && getStudyTypesTags().map((tag, index) => (
          <span key={index} className={classes.tag}>{tag}</span>
        ))}
      </div>

      {/* Footer actions - simplified */}
      <div className={classes.bundleFooter}>
        <div className={classes.actionButtonsContainer}>
          <PrimaryActionButton
            buttonText="Check it out"
            className={classes.bundleButton}
            onClick={handleButtonClick}
          />
        </div>
      </div>
    </div>
  );
}
