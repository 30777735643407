import PartnerCard from "./PartnerCard";
import classes from "./PartnersSection.module.css";

const PartnersSection = () => {
  return (
    <section className={classes.partnersSection}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-5">
            <h2 className={classes.sectionTitle}>Our Trusted Partners</h2>
            <p className={classes.sectionSubtitle}>
              Collaborating with leading institutions to advance research and innovation
            </p>
          </div>
        </div>
        <div className={`row ${classes.cardsContainer}`}>
          <PartnerCard 
            name="Edrak"
            desc="Medical student initiative for research excellence"
            image={require("../images/edrak.png")}
            message="Researchers Hub has been instrumental in creating an environment rich with collaboration opportunities for our students." 
          />
          <PartnerCard
            name="Scientific Research"
            desc="Empowering students with research skills and innovation"
            image={require("../images/scientific.jpg")}
            message="Our partnership with ResearchersHub is helping foster research excellence and empowering the next generation of innovators." 
          />
          <PartnerCard
            name="Become a Partner"
            desc="Let's collaborate to shape the future of research"
            image={require("../images/paper.png")}
            message="Partner with us to empower researchers, drive innovation, and create lasting impact in the scientific community. Contact us to explore partnership opportunities." 
          />
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
