import React from "react";
import { Link } from "react-router-dom";
import classes from "./ResourcesPage.module.css";
import PrimaryActionButton from "../components/PrimaryActionButton";

// We'll use Material Icons instead of image files
const ResourcesPage = () => {
  // Card data
  const resourceCards = [
    {
      id: 1,
      title: "RH Community",
      icon: "group",
      description: "Connect with researchers worldwide and join our thriving academic community.",
      buttonText: "Join Community",
      path: "/community",
      gradient: "community"
    },
    {
      id: 2,
      title: "Find a Colleague",
      icon: "school",
      description: "Explore and connect with colleagues that match your academic interests and goals.",
      buttonText: "Find Colleagues",
      path: "/find-colleague",
      gradient: "college"
    },
    {
      id: 3,
      title: "Get a Mentor",
      icon: "person",
      description: "Connect with experienced researchers who can guide your academic journey.",
      buttonText: "Coming Soon",
      path: "/mentors",
      gradient: "mentor",
      disabled: true
    },
    {
      id: 4,
      title: "Research Schools",
      icon: "search", 
      description: "Discover top research institutions and their specialized programs.",
      buttonText: "Browse Schools",
      path: "/schools",
      gradient: "schools",
    }
  ];

  return (
    <div className={classes.resourcesPage}>
      {/* Hero Section */}
      <section className={classes.heroSection}>
        <div className="container">
          <div className={classes.heroContent}>
            <h1 className={classes.pageTitle}>Academic Resources</h1>
            <p className={classes.pageDescription}>
              Discover the tools and connections you need to advance your research journey
            </p>
          </div>
        </div>
      </section>

      {/* Resources Cards Section */}
      <section className={classes.resourcesSection}>
        <div className="container">
          <div className="row">
            {resourceCards.map((card) => (
              <div key={card.id} className="col-lg-6 col-md-6 mb-4">
                <div className={`${classes.resourceCard} ${classes[card.gradient]}`}>
                  <div className={classes.cardContent}>
                    <div className={classes.iconContainer}>
                      <span className="material-symbols-outlined" style={{ fontSize: '32px', color: '#fff' }}>
                        {card.icon}
                      </span>
                    </div>
                    <h2 className={classes.cardTitle}>{card.title}</h2>
                    <p className={classes.cardDescription}>{card.description}</p>
                    <div className={classes.cardAction}>
                      {card.disabled ? (
                        <PrimaryActionButton
                          buttonText={card.buttonText}
                          className={`${classes.actionButton} ${classes.disabledButton}`}
                          disabled={true}
                        />
                      ) : (
                        <Link to={card.path}>
                          <PrimaryActionButton
                            buttonText={card.buttonText}
                            className={classes.actionButton}
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className={classes.cardShapeDivider}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                      <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

    </div>
  );
};

export default ResourcesPage; 