import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { useCallback, useRef } from "react";

export default function useCheckIfAuthenticated() {
  // Use a ref to track if authentication check is in progress
  const checkInProgress = useRef(false);

  const getNewAccessToken = useCallback((method, url, user, setAuth) => {
    return axios({
      method,
      url: process.env.REACT_APP_BACKEND_API_URL + url,
      headers: {
        Authorization: "Bearer " + user?.refreshToken,
      },
      data: {},
    })
      .then((response) => {
        setAuth(true, response.data);
      })
      .catch((error) => {
        setAuth(false);
      });
  }, []);

  const checkIfAuthenticated = useCallback(async (setAuth) => {
    // Prevent multiple simultaneous checks
    if (checkInProgress.current) {
      return;
    }

    try {
      checkInProgress.current = true;
      const user = JSON.parse(localStorage.getItem("user"));
      const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn")) || false;

      if (isLoggedIn && user) {
        const decodedToken = jwtDecode(user["accessToken"]);
        const current_time = Date.now().valueOf() / 1000;

        if (decodedToken.exp > current_time) {
          setAuth(true, user);
        } else {
          await getNewAccessToken("get", "/refresh-token", user, setAuth);
        }
      } else {
        setAuth(false);
      }
    } catch (error) {
      setAuth(false);
    } finally {
      checkInProgress.current = false;
    }
  }, [getNewAccessToken]);

  return { checkIfAuthenticated };
}
