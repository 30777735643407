import { useEffect, useState } from "react";
import PrimaryActionButton from "./PrimaryActionButton";
import classes from "./RequestItem.module.css";
import SingleCategoryItem from "./SingleCategoryItem";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { useSubmit, useActionData, useNavigation } from "react-router-dom";
import whatsappIcon from "../images/whatsappLogo.png";
import emailIcon from "../images/emailLogo.png";
import telegramIcon from "../images/telegramLogo.png";


const RequestItem = ({
  userId,
  groupId,
  groupName,
  name,
  phone,
  telegram,
  email,
  year,
  university,
  major,
  interests,
  isExperienced,
  willingToLead,
  haveAnIdea,
  haveAnSupervisor,
  setJoinRequests,
}) => {
  const [action, setAction] = useState(null);
  const actionData = useActionData();
  const navigation = useNavigation();
  const submit = useSubmit();

  const handleAcceptOrDecline = (action) => {
    setAction(action);

    const data = {
      participantId: userId,
      groupId,
      action,
    };

    submit(
      {
        intent: "handle-join",
        ...data,
      },
      { method: "post", action: `/group/${groupId}/${groupName}` }
    );
  };

  useEffect(() => {
    if (actionData && actionData.intent === "handle-join") {
      if (actionData.status === "ok") {
        toast.success(actionData?.response?.data?.msg);

        // removes the current request from the joinRequests list
        setJoinRequests((prevJoinRequests) =>
          prevJoinRequests.filter((joinRequest) => joinRequest.id !== userId)
        );
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error?.response?.data?.msg ||
            "An error occurred. Please try again later."
        );
      }
      setAction(null);
    }
  }, [actionData]);

  // Helper function to format interests nicely
  const formatInterests = () => {
    if (!interests || interests.length === 0) return "No specific interests";
    
    return interests
      .map((interest) => interest.label)
      .join(", ");
  };

  return (
    <div className={classes.participantContainer}>
      <div className={classes.participantContent}>
        <div className={classes.participantInfo}>
          <h3 className={classes.userName}>{name}</h3>
          
          <div className={classes.detailsRow}>
            {year && year !== "None" && (
              <div className={classes.detailTag}>
                <span className="material-symbols-outlined">school</span>
                {year}
              </div>
            )}
            
            {university && university !== "None" && (
              <div className={classes.detailTag}>
                <span className="material-symbols-outlined">account_balance</span>
                {university}
              </div>
            )}
            
            {major && major !== "None" && (
              <div className={classes.detailTag}>
                <span className="material-symbols-outlined">psychology</span>
                {major}
              </div>
            )}
          </div>
          
          <div className={classes.interestsSection}>
            <h4 className={classes.interestsTitle}>Research Interests:</h4>
            <p className={classes.interestsText}>{formatInterests()}</p>
          </div>
          
          <div className={classes.specialTags}>
            {isExperienced && (
              <SingleCategoryItem category="experienced" />
            )}
            {willingToLead === "true" && (
              <SingleCategoryItem category="willing to lead" />
            )}
            {haveAnIdea === "true" && (
              <SingleCategoryItem category="have an idea" />
            )}
            {haveAnSupervisor === "true" && (
              <SingleCategoryItem category="have a supervisor" />
            )}
          </div>

          <div className={classes.contactSection}>
            {phone && (
              <a 
                href={`https://wa.me/${phone}`} 
                target="_blank" 
                rel="noopener noreferrer"
                className={classes.contactButton}
                aria-label="Contact via WhatsApp"
              >
                <img className={classes.contactIcon} src={whatsappIcon} alt="WhatsApp" />
                <span>WhatsApp</span>
              </a>
            )}
            
            {telegram && (
              <a 
                href={`https://t.me/${telegram}`} 
                target="_blank" 
                rel="noopener noreferrer"
                className={classes.contactButton}
                aria-label="Contact via Telegram"
              >
                <img className={classes.contactIcon} src={telegramIcon} alt="Telegram" />
                <span>Telegram</span>
              </a>
            )}
            
            {email && (
              <a 
                href={`mailto:${email}`}
                className={classes.contactButton}
                aria-label="Contact via Email"
              >
                <img className={classes.contactIcon} src={emailIcon} alt="Email" />
                <span>Email</span>
              </a>
            )}
          </div>
        </div>
        
        <div className={classes.actionsContainer}>
          <PrimaryActionButton
            className={`${classes.actionButton} ${classes.acceptButton}`}
            buttonText={
              navigation.state === "idle" ? (
                "Accept"
              ) : action === "accept" ? (
                <BeatLoader color="white" loading size={10} />
              ) : (
                "Accept"
              )
            }
            onClick={() => handleAcceptOrDecline("accept")}
          />
          
          <PrimaryActionButton
            className={`${classes.actionButton} ${classes.removeButton}`}
            buttonText={
              navigation.state === "idle" ? (
                "Decline"
              ) : action === "decline" ? (
                <BeatLoader color="white" loading size={10} />
              ) : (
                "Decline"
              )
            }
            onClick={() => handleAcceptOrDecline("decline")}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestItem;
