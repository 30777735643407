import axios from "axios";
import { useCallback, useState, useMemo, useRef } from "react";

export default function useHttp() {
  const [isLoading, setIsloading] = useState(false);
  const [actionData, setActionData] = useState(null);
  // Use a ref to track in-flight requests
  const pendingRequests = useRef(new Map());

  const trimStringsWithinObject = useCallback((obj) => {
    if (obj) {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "string") {
          obj[key] = obj[key].trim();
        }
      });
    }
    return obj;
  }, []);

  const createRequestKey = useCallback((method, url, data) => {
    return `${method}:${url}:${JSON.stringify(data)}`;
  }, []);

  const prepareRequestBody = useCallback((
    method,
    url,
    data,
    isAuthenticated,
    abortController
  ) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn")) || false;

    const requestObj = {
      method,
      url: process.env.REACT_APP_BACKEND_API_URL + url,
      signal: abortController?.signal || null,
    };

    // Only set auth header if both isLoggedIn is true and we have a valid user with accessToken
    if (isAuthenticated && isLoggedIn && user?.accessToken) {
      requestObj.headers = {
        Authorization: "Bearer " + user.accessToken,
      };
    }

    if (method === "get") {
      requestObj.params = trimStringsWithinObject(data);
      requestObj.data = {};
    } else {
      data = trimStringsWithinObject(data);
      requestObj.data = data;
    }

    return requestObj;
  }, [trimStringsWithinObject]);

  const defaultResolved = useCallback((response) => ({
    status: "ok",
    response,
  }), []);

  const defaultRejected = useCallback((error) => ({
    status: "error",
    error,
  }), []);

  const sendRequest = useCallback(
    async (
      method,
      url,
      data,
      resolved = defaultResolved,
      rejected = defaultRejected,
      abortController = null
    ) => {
      const requestKey = createRequestKey(method, url, data);
      
      // Check if there's already a pending request
      if (pendingRequests.current.has(requestKey)) {
        return pendingRequests.current.get(requestKey);
      }

      const isAuthenticated = JSON.parse(localStorage.getItem("isLoggedIn")) || false;
      const requestBody = prepareRequestBody(
        method,
        url,
        data,
        isAuthenticated,
        abortController
      );

      // Create the promise for this request
      const promise = axios(requestBody)
        .then((response) => {
          pendingRequests.current.delete(requestKey);
          return resolved(response);
        })
        .catch((error) => {
          pendingRequests.current.delete(requestKey);
          return rejected(error);
        });

      // Store the promise
      pendingRequests.current.set(requestKey, promise);
      return promise;
    },
    [prepareRequestBody, defaultResolved, defaultRejected, createRequestKey]
  );

  const sendRequestAndTrackProgress = useCallback(
    async (method, url, data, abortController = null) => {
      const requestKey = createRequestKey(method, url, data);
      
      // Check if there's already a pending request
      if (pendingRequests.current.has(requestKey)) {
        return pendingRequests.current.get(requestKey);
      }

      setIsloading(true);
      const isAuthenticated = JSON.parse(localStorage.getItem("isLoggedIn")) || false;
      const requestBody = prepareRequestBody(
        method,
        url,
        data,
        isAuthenticated,
        abortController
      );

      try {
        const promise = axios(requestBody)
          .then((response) => {
            setActionData({ status: "ok", response });
            pendingRequests.current.delete(requestKey);
            return response;
          })
          .catch((error) => {
            setActionData({ status: "error", error });
            pendingRequests.current.delete(requestKey);
            throw error;
          })
          .finally(() => {
            setIsloading(false);
          });

        pendingRequests.current.set(requestKey, promise);
        return promise;
      } catch (error) {
        pendingRequests.current.delete(requestKey);
        setIsloading(false);
        throw error;
      }
    },
    [prepareRequestBody, createRequestKey]
  );

  return useMemo(() => ({
    sendRequest,
    sendRequestAndTrackProgress,
    isLoading,
    actionData,
  }), [sendRequest, sendRequestAndTrackProgress, isLoading, actionData]);
}
