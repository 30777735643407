import { useState, useEffect, useContext } from "react";
import classes from "./FindColleaguePage.module.css";
import ParticipantItem from "../components/ParticipantItem";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import filterIcon from "../images/filterIcon.png";
import { specialties as allInterests } from "../data/constants";
import SelectInterestsField from "../components/SelectInterestsField";
import { userContext } from "../components/LoggedInBaseLayout";
import PrimaryActionButton from "../components/PrimaryActionButton";

const FindColleaguePage = () => {
  const [users, setUsers] = useState([]);
  const [suggestedColleagues, setSuggestedColleagues] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [interestsError, setInterestsError] = useState(false);
  const [interestsErrorMessage, setInterestsErrorMessage] = useState("");
  const [universityFilter, setUniversityFilter] = useState("global");
  const [showTooltip, setShowTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userDetails } = useContext(userContext);
  const loaderData = useLoaderData();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (loaderData) {
      const { users, suggestedColleagues } = loaderData;
      
      setIsLoading(true);
      users.then(({ response }) => {
        setUsers(response.data.msg || []);
        setIsLoading(false);
      }).catch(({ error }) => {
        if (error?.response?.data?.msg || error) {
          toast.error(error?.response?.data?.msg || "Failed to fetch users");
          setIsLoading(false);
        }
      });
      
      suggestedColleagues.then(({ response }) => {
        setSuggestedColleagues(response.data.msg || []);
      }).catch(({ error }) => {
        if (error?.response?.data?.msg || error) {
          console.error("Failed to fetch suggested colleagues:", error);
        }
      });
    }
  }, [loaderData]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    
    // Clear existing params
    params.delete('university');
    params.delete('interest');

    // Add university filter if needed
    if (universityFilter === "university") {
      params.set('university', 1);
    }

    // Add interest filters if any are selected
    if (selectedInterests.length > 0) {
      selectedInterests.forEach(interest => {
        if (interest && interest.value) {
          params.append('interest', interest.value.toString());
        }
      });
    }

    setSearchParams(params);
  }, [selectedInterests, universityFilter, userDetails, setSearchParams]);

  return (
    <section className={classes.findColleaguePage}>
      <div className={`container ${classes.welcomeMessageContainer}`}>
        <div className={classes.communityHeader}>
          <h1 className={classes.communityTitle}>Find Colleagues</h1>
          <p className={classes.communitySubtitle}>
            Connect with researchers worldwide and discover potential collaborators based on shared interests
          </p>
        </div>
      </div>
      
      <div className={`container ${classes.usersContainer}`}>
        {/* Filter toggle button */}
        <div className={classes.filterToggleContainer}>
          <button 
            className={`${classes.filterButton} ${showFilters ? classes.active : ''}`}
            onClick={() => setShowFilters(!showFilters)}
            aria-label="Toggle filters"
          >
            <img src={filterIcon} alt="Filter" className={classes.filterIcon} />
            <span>Filters</span>
          </button>
        </div>
        
        {/* Filter section */}
        {showFilters && (
          <div className={classes.filterContainer}>
            <div className={classes.filterSection}>
              <h3 className={classes.filterHeading}>Location</h3>
              <div className={classes.filterButtons}>
                <button
                  className={`${classes.scopeFilterButton} ${universityFilter === 'global' ? classes.active : ''}`}
                  onClick={() => setUniversityFilter('global')}
                >
                  <span className="material-symbols-outlined">public</span>
                  Global
                </button>
                <button
                  className={`${classes.scopeFilterButton} ${universityFilter === 'university' ? classes.active : ''}`}
                  onClick={() => setUniversityFilter('university')}
                >
                  <span className="material-symbols-outlined">school</span>
                  My University
                </button>
              </div>
            </div>
            
            <div className={classes.filterSection}>
              <h3 className={classes.filterHeading}>Research Interests</h3>
              <SelectInterestsField
                allInterests={allInterests}
                interests={selectedInterests}
                setInterests={setSelectedInterests}
                required={false}
                interestsError={interestsError}
                setInterestsError={setInterestsError}
                interestsErrorMessage={interestsErrorMessage}
                setInterestsErrorMessage={setInterestsErrorMessage}
              />
            </div>
            
            <div className={classes.filterActions}>
              <button 
                className={classes.clearFiltersButton}
                onClick={() => {
                  setSelectedInterests([]);
                  setUniversityFilter('global');
                }}
              >
                Clear Filters
              </button>
            </div>
          </div>
        )}

        {/* Suggested Colleagues Section */}
        {suggestedColleagues.length > 0 && (
          <div className={classes.suggestedColleaguesSection}>
            <div className={classes.sectionHeader}>
              <h2 className={classes.sectionTitle}>
                Recommended Colleagues
              </h2>
              <div 
                className={classes.infoIcon}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <span className="material-symbols-outlined">info</span>
                {showTooltip && (
                  <div className={classes.tooltip}>
                    These colleagues are matched based on similar university, major, year, and research interests to help you find potential collaborators.
                  </div>
                )}
              </div>
            </div>
            
            <div className={classes.suggestedColleaguesContainer}>
              {suggestedColleagues.map((user, key) => (
                user.telegram && (
                  <div className={classes.suggestedColleague} key={key}>
                    <div className={classes.matchBadge}>Best Match</div>
                    <ParticipantItem
                      name={user.name}
                      year={user.year?.label}
                      university={user.university?.label}
                      major={user.major?.label}
                      interests={user.intreset}
                      telegram={user.telegram}
                      isOwner={false}
                      className={classes.suggestedParticipant}
                    />
                  </div>
                )
              ))}
            </div>
          </div>
        )}

        {/* Main User List */}
        <div className={classes.userListSection}>
          <div className={classes.sectionHeader}>
            <h2 className={classes.sectionTitle}>
              All Colleagues
            </h2>
            <p className={classes.resultCount}>
              {isLoading ? 'Loading...' : `Showing ${users.length} colleagues`}
            </p>
          </div>
          
          {isLoading ? (
            <div className={classes.loadingState}>
              <div className={classes.loadingSpinner}></div>
              <p>Loading colleagues...</p>
            </div>
          ) : users.length === 0 ? (
            <div className={classes.emptyState}>
              <span className="material-symbols-outlined">search_off</span>
              <p>No colleagues found matching your criteria.</p>
              {(selectedInterests.length > 0 || universityFilter === 'university') && (
                <button 
                  className={classes.clearFiltersButton}
                  onClick={() => {
                    setSelectedInterests([]);
                    setUniversityFilter('global');
                  }}
                >
                  Clear All Filters
                </button>
              )}
            </div>
          ) : (
            <div className={classes.userGrid}>
              {users.map((user, key) => (
                user.telegram && (
                  <div className={classes.userCard} key={key}>
                    <ParticipantItem
                      name={user.name}
                      year={user.year?.label}
                      university={user.university?.label}
                      major={user.major?.label}
                      interests={user.intreset}
                      telegram={user.telegram}
                      isOwner={false}
                    />
                  </div>
                )
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FindColleaguePage; 