import { clarity } from 'react-microsoft-clarity';

import { createContext, useEffect, useState, useMemo, useCallback } from "react";
import "./App.css";

// third party packages & components
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// custom components
import LoggedInBaseLayout from "./components/LoggedInBaseLayout";
import NonLoggedInBaseLayout from "./components/NonLoggedInBaseLayout";
import NonLoggedInHomePage from "./pages/NonLoggedInHomePage";
import ResourcesPage from "./pages/ResourcesPage";
import CommunityPage from "./pages/CommunityPage";
import GroupDetailsPage from "./pages/GroupDetailsPage";
import SchoolsPage from './pages/SchoolsPage';
import EmailVerification from './pages/EmailVerificaiton'
import SuperAdmin from "./pages/SuperAdmin";
import FindColleaguePage from "./pages/FindColleaguePage";
import useHttp from "./custom-hooks/useHttp";
import { HomePageActions } from "./actions/HomePageActions";
import { HomePageLoader } from "./loaders/HomePageLoaders";
import { GroupDetailsPageLoader } from "./loaders/GroupDetailsPageLoader";
import { GroupDetailsPageActions } from "./actions/GroupDetailsPageActions";
import { SuperAdminLoader } from "./loaders/SuperAdminLoader";
import useCheckIfAuthenticated from "./custom-hooks/useRenewAccessToken";
import ResetPassword from "./pages/ResetPassword";
import EditProfilePage from "./pages/EditProfilePage";
import { EditProfileLoader } from "./loaders/EditProfileLoader";
import { findColleaguePageLoader } from "./loaders/FindColleaguePageLoader";

// context
export const authContext = createContext();
export const loginContext = createContext();
export const signupContext = createContext();
export const forgotPasswordContext = createContext();
clarity.init('poj4ick1pr');

// Create router outside component
const createAppRouter = (config) => createBrowserRouter([
  {
    path: "/",
    element: config.isAuthenticated ? (
      <authContext.Provider value={config.authValue}>
        <LoggedInBaseLayout />
      </authContext.Provider>
    ) : (
      <authContext.Provider value={config.authValue}>
        <loginContext.Provider value={config.loginValue}>
          <signupContext.Provider value={config.signupValue}>
            <forgotPasswordContext.Provider value={config.forgotValue}>
              <NonLoggedInBaseLayout />
            </forgotPasswordContext.Provider>
          </signupContext.Provider>
        </loginContext.Provider>
      </authContext.Provider>
    ),
    action: HomePageActions(config.sendRequest),
    children: [
      {
        index: true,
        loader: HomePageLoader(config.sendRequest),
        element: config.isAuthenticated ? <ResourcesPage /> : <NonLoggedInHomePage />,
      },
      {
        path: "/group/:id/:name",
        element: <GroupDetailsPage />,
        loader: GroupDetailsPageLoader(config.sendRequest),
        action: GroupDetailsPageActions(config.sendRequest),
      },
      {
        path: "/profile/edit",
        element: <EditProfilePage />,
        loader: EditProfileLoader(config.sendRequest),
      },
      {
        path: "/admin",
        element: <SuperAdmin />,
        loader: SuperAdminLoader(config.sendRequest),
      },
      {
        path: "/reset-password/:password_token",
        element: <ResetPassword />,
      },
      {
        path: "/verify-email/:verification_token",
        element: <EmailVerification />,
      },
      {
        path: "/schools",
        element: <SchoolsPage />,
      },
      {
        path: "/find-colleague",
        element: <FindColleaguePage />,
        loader: findColleaguePageLoader(config.sendRequest),
      },
      {
        path: "/community",
        element: <CommunityPage />,
        loader: HomePageLoader(config.sendRequest),
      },
    ],
  },
]);

function App() {
  const { sendRequest } = useHttp();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { checkIfAuthenticated } = useCheckIfAuthenticated();

  // Define setAuth with useCallback to maintain reference stability
  const setAuth = useCallback((status, value = null) => {
    if (status) {
      localStorage.setItem("isLoggedIn", JSON.stringify(true));
      localStorage.setItem("user", JSON.stringify(value));
    } else {
      localStorage.setItem("isLoggedIn", JSON.stringify(false));
      localStorage.removeItem("user");
      window.history.pushState({}, "", "/");
    }
    setIsAuthenticated(status);
  }, []);

  // Memoize context values
  const authValue = useMemo(() => ({ isAuthenticated, setAuth }), [isAuthenticated, setAuth]);
  const loginValue = useMemo(() => ({ showLoginModal, setShowLoginModal }), [showLoginModal]);
  const signupValue = useMemo(() => ({ showSignupModal, setShowSignupModal }), [showSignupModal]);
  const forgotValue = useMemo(
    () => ({ showForgotPasswordModal, setShowForgotPasswordModal }),
    [showForgotPasswordModal]
  );

  // Memoize router configuration
  const routerConfig = useMemo(() => ({
    isAuthenticated,
    authValue,
    loginValue,
    signupValue,
    forgotValue,
    sendRequest
  }), [isAuthenticated, authValue, loginValue, signupValue, forgotValue, sendRequest]);

  // Memoize router instance
  const router = useMemo(() => createAppRouter(routerConfig), [routerConfig]);

  // Check authentication status only once on mount
  useEffect(() => {
    const initialCheck = async () => {
      await checkIfAuthenticated(setAuth);
    };
    initialCheck();
  }, [checkIfAuthenticated, setAuth]);

  return (
    <div className="App">
      <RouterProvider router={router} />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
