import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./GroupItem.module.css";
import PrimaryActionButton from "./PrimaryActionButton";
import { userContext } from "../components/LoggedInBaseLayout";
import useHttp from "../custom-hooks/useHttp";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

const HomePageGroupItem = ({
  id,
  name,
  title,
  requests,
  participantsCount,
  category,
  subCategory,
  isVisible,
  seatsAvailable
}) => {
  const [status, setStatus] = useState("");
  const { userDetails } = useContext(userContext);
  const [action, setAction] = useState(null);
  const navigate = useNavigate();
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();

  useEffect(() => {
    if (requests) {
      requests.map((request) => {
        if (request?.id === userDetails?.id) {
          setStatus(request.status.label);
        }
      });
    }
  }, [requests, userDetails]);

  const withdrawJoinRequest = (action) => {
    setAction(action);

    const data = {
      groupId: id,
    };

    sendRequestAndTrackProgress("post", "/leave/", data);
  };

  const deleteHistroy = (action) => {
    setAction(action);

    const data = {
      groupId: id,
    };

    sendRequestAndTrackProgress("post", "/delete-history/", data);
  };

  useEffect(() => {
    if (actionData) {
      if (action === "withdraw-join-request") {
        if (actionData.status === "ok") {
          toast.success("Successfully left the group");
          navigate("/");
        } else if (actionData.status === "error") {
          toast.error(
            actionData.error?.response?.data?.msg || "Failed to leave group"
          );
        }
      } else if (action === "delete-history") {
        if (actionData.status === "ok") {
          toast.success("Successfully removed from your groups");
          navigate("/");
        } else if (actionData.status === "error") {
          toast.error(
            actionData.error?.response?.data?.msg ||
              "Failed to remove from your groups"
          );
        }
      }
      setAction(null);
    }
  }, [actionData]);

  return (
    <div className={`${classes.groupContainer}`}>
      <div className="row">
        <div className="col-lg col-12">
          <p>{title}</p>
          <p className={classes.groupName}>{name}</p>
          <p
            className={`${classes.status} ${
              status === "Pending"
                ? classes.inReview
                : status === "Declined"
                ? classes.declined
                : status === "Removed"
                ? classes.declined
                : status === "Leaved"
                ? classes.declined
                : classes.accepted
            }`}
          >
            {status} 
          </p>
          {seatsAvailable <= 0 && <span className={`${classes.status} ${classes.full}`}>Full</span>}
          {!isVisible && (
            <p className={`${classes.inReview} ${classes.status}`}>Not accepting new authors</p>
          )}
          <p
            className={classes.metaData}
          >{`${participantsCount} Participants | ${category} | ${subCategory}`}</p>
          
        </div>
        <div className="col-lg-auto col-12 d-flex align-items-center justify-content-end">
          
          {(status === "Accepted" || status === "Owner") && (
            <Link
              to={`/group/${id}/${name.toLowerCase().split(" ").join("-")}`}
              className={classes.actionButtonLink}
            >
              <PrimaryActionButton
                className={classes.actionButton}
                buttonText="view 🔍"
              />
            </Link>
          )}
          {status === "Pending" && (
            <PrimaryActionButton
              className={classes.actionButton}
              buttonText={
                isLoading ? (
                  action === "withdraw-join-request" ? (
                    <BeatLoader color="white" loading size={10} />
                  ) : (
                    "delete my request 🗑"
                  )
                ) : (
                  "delete my request 🗑"
                )
              }
              onClick={() => withdrawJoinRequest("withdraw-join-request")}
            />
          )}
          {status === "Declined" ||
          status === "Removed" ||
          status === "Leaved" ? (
            <PrimaryActionButton
              className={classes.actionButton}
              buttonText={
                isLoading ? (
                  action === "delete-history" ? (
                    <BeatLoader color="white" loading size={10} />
                  ) : (
                    "remove from my groups ❌"
                  )
                ) : (
                  "remove from my groups ❌"
                )
              }
              onClick={() => deleteHistroy("delete-history")}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HomePageGroupItem;
