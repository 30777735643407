import { Link, useNavigate } from "react-router-dom";
import { useRef, useContext } from "react";
import classes from "./LoggedInNavigationBar.module.css";
import NavBarBusinessLogo from "./NavBarBusinessLogo";
import PrimaryActionButton from "./PrimaryActionButton";
import { authContext } from "../App";

const LoggedInNavigationBar = () => {
  const menuButtonRef = useRef();
  const navigate = useNavigate();

  const logout = () => {
    // Redirect to home page
    setAuth(false);
  };

  const { setAuth } = useContext(authContext);

  return (
    <>
      <nav className={`navbar navbar-expand-md ${classes.navbarContainer}`}>
        <div className="container">
          <div>
            <Link className="businessLogo" to="/">
              <NavBarBusinessLogo />
            </Link>
          </div>
          <button
            style={{
              boxShadow: "none",
              outline: "none",
              border: "none",
            }}
            ref={menuButtonRef}
            className={`navbar-toggler m-0`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className={`material-symbols-outlined ${classes.menuIcon}`}>
              menu
            </span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarTogglerDemo01"
          >
            <div className="d-flex justify-content-lg-start justify-content-center ">
              <ul className="navbar-nav me-lg-auto me-0 mb-2 mb-lg-0">
                <li
                  className={`nav-item ${classes.customNavItem}`}
                  style={{ paddingRight: "0" }}
                >
                  <div>
                    <PrimaryActionButton
                      buttonText="Edit Profile"
                      className={classes.signupButton}
                      style={{ width: "auto" }}
                      onClick={() => navigate("/profile/edit")}
                    />
                  </div>
                </li>
                <li
                  className={`nav-item ${classes.customNavItem}`}
                  style={{ paddingRight: "0" }}
                >
                  <div>
                    <PrimaryActionButton
                      buttonText="Log Out"
                      className={classes.signupButton}
                      onClick={logout}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default LoggedInNavigationBar;
