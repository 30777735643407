import React from "react";
import classes from "./ResearchServiceBundle.module.css";
import PrimaryActionButton from "./PrimaryActionButton";

export default function ResearchServiceBundle(props) {
  // Handle button click to open URL in new tab
  const handleButtonClick = () => {
    if (props.url) {
      window.open(props.url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className={classes.serviceContainer}>
      {/* Service Header with Icon and Name - Updated to match Bundle.js layout */}
      <div className={classes.serviceHeader}>
        <div className={classes.headerLeft}>
          <h2 className={classes.serviceName}>{props.name}</h2>
          <div className={classes.specialTagsContainer}>
            {props.hasFree && (
              <span className={`${classes.specialTag} ${classes.freeTag}`}>
                <span className="material-symbols-outlined">card_giftcard</span>
                <span>Free Resources</span>
              </span>
            )}
            {props.hasDiscount && (
              <span className={`${classes.specialTag} ${classes.discountTag}`}>
                <span className="material-symbols-outlined">local_offer</span>
                <span>Discount Available</span>
              </span>
            )}
          </div>
        </div>
        <div className={classes.headerRight}>
          {props.icon ? (
            <img 
              className={classes.serviceIcon}
              src={props.icon} 
              alt={props.name}
            />
          ) : (
            <span className="material-symbols-outlined">science</span>
          )}
        </div>
      </div>

      {/* Service Description */}
      <div className={classes.serviceDescription}>
        <p>{props.description}</p>
      </div>

      {/* Main Features Section */}
      {props.mainFeatures && props.mainFeatures.length > 0 && (
        <div className={classes.featuresSection}>
          <div className={classes.featuresList}>
            {props.mainFeatures.map((feature, index) => (
              <div key={index} className={classes.featureItem}>
                <span className="material-symbols-outlined">check_circle</span>
                <span className={classes.featureText}>{feature}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Service Details */}
      {props.details && props.details.length > 0 && (
        <div className={classes.serviceDetails}>
          <h3 className={classes.detailsTitle}>Service Information</h3>
          <div className={classes.detailsGrid}>
            {props.details.map((detail, index) => (
              <div key={index} className={classes.detailItem}>
                <span className="material-symbols-outlined">{detail.icon || "check_circle"}</span>
                <div className={classes.detailText}>
                  <span className={classes.detailLabel}>{detail.label}:</span>
                  <span className={classes.detailValue}>{detail.value}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Service Tags */}
      <div className={classes.tagsContainer}>
        {props.tags && props.tags.length > 0 && 
          props.tags.map((tag, index) => (
            <span key={index} className={classes.tag}>{tag}</span>
          ))
        }
      </div>

      {/* Action Button */}
      <div className={classes.serviceFooter}>
        <PrimaryActionButton
          buttonText="Check it out"
          className={classes.serviceButton}
          onClick={handleButtonClick}
        />
      </div>
    </div>
  );
} 