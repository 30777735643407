import classes from "./PartnerCard.module.css";

const PartnerCard = ({ message, name, desc, image }) => {
  return (
    <div className={`col-lg-4 col-md-6 col-sm-12 ${classes.partnerCard}`}>
      <div className={classes.card}>
        <div className={classes.logoContainer}>
          <div 
            className={classes.logo} 
            style={{ backgroundImage: `url("${image}")` }}
          ></div>
        </div>
        
        <div className={classes.content}>
          <h3 className={classes.partnerName}>{name}</h3>
          <p className={classes.partnerDesc}>{desc}</p>
          
          <div className={classes.testimonial}>
            <svg className={classes.quoteIcon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 7L8 11L11 13L9 17H3L5 13L2 11L4 7H10Z" fill="currentColor" fillOpacity="0.3"/>
              <path d="M22 7L20 11L23 13L21 17H15L17 13L14 11L16 7H22Z" fill="currentColor" fillOpacity="0.3"/>
            </svg>
            <p className={classes.testimonialText}>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerCard;
