import { defer } from "react-router-dom";

const myGroupsLoader = (sendRequest) => (args) => {
  return sendRequest("get", "/my-groups").then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

const isEmailVerifiedLoader = (sendRequest) => (args) => {
  return sendRequest("get", "/is-email-verified").then((resp) => {
    return resp;
  });
};

const threeGroupsLoader = (sendRequest) => (args) => {
  const user = JSON.parse(localStorage.getItem("user"));

  let url = "/three-groups";

  if (user) {
    url = "/all-groups";
  }

  return sendRequest("get", url).then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

const counterLoader = (sendRequest) => (args) => {
  return sendRequest("get", "/counters").then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

const usersLoader = (sendRequest) => (args) => {
  return sendRequest("get", "/users").then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

export const HomePageLoader = (sendRequest) => async (args) => {
  const myGroups = myGroupsLoader(sendRequest)(args);
  const threeGroups = threeGroupsLoader(sendRequest)(args);
  const counter = counterLoader(sendRequest)(args)
  const isEmailVerified = isEmailVerifiedLoader(sendRequest)(args);
  const users = usersLoader(sendRequest)(args);
  return defer({
    myGroups,
    threeGroups,
    counter,
    isEmailVerified,
    users,
  });
};
