import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EmailVerification = () => {
  const { verification_token } = useParams();
  const [message, setMessage] = useState("Verifying email...");
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) return;
    mounted.current = true;

    const verifyEmail = async () => {
      try {
        const response = await fetch(`/api/verify-email/${verification_token}`);
        const data = await response.json();

        if (response.ok) {
          setMessage("Email verified successfully!");
          toast.success("Email verified successfully!");
        } else {
          setMessage("Email verification failed");
          toast.error("Email verification failed");
        }
      } catch (error) {
        setMessage("An error occurred during verification");
        toast.error("Verification error");
      }
    };

    verifyEmail();
  }, []); // Empty dependency array since we're using the mount flag

  return (
    <section style={{ height: "100vh" }}>
      <div className="container">
        <div className="d-flex justify-content-center align-items-center h-100">
          <h3>{message}</h3>
        </div>
      </div>
    </section>
  );
};

export default EmailVerification;
