import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import classes from "./ResendVerificationLink.module.css";

const COOLDOWN_TIME = 60; // 60 seconds cooldown

const ResendVerificationLink = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    let timer;
    if (cooldown > 0) {
      timer = setInterval(() => {
        setCooldown(prev => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [cooldown]);

  const handleResendVerification = async () => {
    if (isLoading || cooldown > 0) return;
    
    setIsLoading(true);
    try {
      const response = await fetch("/api/resend-verification", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${user?.accessToken}`,
          "Content-Type": "application/json",
        },
        credentials: "include"
      });
      const data = await response.json();
      if (response.ok) {
        toast.success("Verification email sent successfully!");
        setCooldown(COOLDOWN_TIME);
      } else {
        throw new Error(data.msg);
      }
    } catch (error) {
      toast.error(
        error?.message || 
        "Failed to send verification email. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (cooldown > 0) {
    return (
      <span className={classes.disabled}>
        Resend available in {cooldown}s
      </span>
    );
  }

  return (
    <span className={classes.link} onClick={handleResendVerification}>
      {isLoading ? (
        <BeatLoader color="#0d6efd" loading size={8} />
      ) : (
        "Resend verification email"
      )}
    </span>
  );
};

export default ResendVerificationLink; 